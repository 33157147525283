<template>
  <div class="home">
    <div class="content">
      <van-nav-bar :title="company" class="navbar" />
      <div class="contentbox">
        <div class="content-title">
          <div class="content-ctx">
            当前进度
            <span
              >第 <b>{{ days }}</b> 天</span
            >
          </div>

          <img
            src="https://lesson.iapeap.com/images/EmotionalValue/jiayou.png"
            alt=""
          />
        </div>

        <div class="content-ctx2">
          <div class="ctx2Item" v-for="(ctx2Item, ctx2index) in days - 0"></div>
          <div
            class="ctx2Item2"
            v-for="(ctx3Item, ctx3index) in 7 - days"
          ></div>
        </div>
        <div class="num">
          <span>{{ days }}</span
          >/7
        </div>
        <div class="ctx3">
          <div class="ctx3-content1">Day {{ days }}</div>
          <div class="ctx3-content2">{{ daily_sentence }}</div>
          <div class="ctx3-content2" v-if="!daily_sentence">
            还未开营，请耐心等待。
          </div>

          <!-- <div class="ctx3-content3">开启【情绪价值】之旅</div> -->
        </div>
        <div class="shengchengriqian" @click="riqian()">
          <img
            src="https://lesson.iapeap.com/images/EmotionalValue/shuqian.png"
            alt=""
          />
          <div>生成日签</div>
        </div>
        <div class="ctx4">
          <button v-if="days >= 1" @click="gotosetouindex">今日练习</button>
          <button v-if="stage == 1 && days == 0" @click="gotosetouindex">
            开营准备
          </button>
        </div>
        <div class="ctx5">
          <button v-if="!stage">暂未开营</button>
        </div>
      </div>
    </div>

    <div class="tanchuang" v-show="showimg">
      <div class="riqian">
        <div>
          <img
            class="guanbi"
            @click="showimg = !showimg"
            src="https://lesson.iapeap.com/images/EmotionalValue/guanbi.png"
            alt=""
          />
        </div>
        <div>
          <img
            class="riqianimg"
            :src="daily_pic"
            alt=""
            @mousedown="startTimer"
            @mouseup="stopTimer"
            @touchstart="startTimer"
            @touchend="stopTimer"
          />
        </div>
      </div>
    </div>

    <!-- tabbar -->
    <div class="app-footer">
      <van-tabbar v-model="active" class="tabbar">
        <van-tabbar-item @click="navigateTocircle">
          <span>圈子</span>
          <template #icon="props">
            <img :src="props.active ? icon.inactive : icon.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item @click="navigateToCapsule">
          <span>情绪胶囊</span>
          <template #icon="props">
            <img :src="props.jnh ? icon.jnh : icon.jnh" /> </template
        ></van-tabbar-item>
        <van-tabbar-item @click="navigateTofile">
          <span>练习档案</span>
          <template #icon="props">
            <img :src="props.lxdah ? icon.lxdah : icon.lxdah" /> </template
        ></van-tabbar-item>
      </van-tabbar>
    </div>

    <div class="noover" v-if="shownoover">
      <div class="nooverbox">
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/yiqijiayou.png"
        />
        <div class="nooverbox-ctx">
          <div>在上一个阶段中，你错过了一些练习.....</div>
          为了获得更好的练习收获与体验，在开启新的练习之前，我们希望邀请你进行一些思考，完成一份包含3道简单题目的
          <span>【阶段报告】</span>
        </div>
        <button @click="tofillin">去完成报告 开起练习</button>
      </div>
    </div>

    <div class="over" v-if="showover">
      <div class="overbox">
        <img
          v-if="stage == 1"
          src="https://lesson.iapeap.com/images/EmotionalValue/1jie.png"
          alt=""
        />

        <img
          v-if="stage == 2"
          src="https://lesson.iapeap.com/images/EmotionalValue/2jie.png"
          alt=""
        />

        <img
          v-if="stage == 3"
          src="https://lesson.iapeap.com/images/EmotionalValue/3jie.png"
          alt=""
        />

        <img
          v-if="stage == 4"
          src="https://lesson.iapeap.com/images/EmotionalValue/4jie.png"
          alt=""
        />
        <div>恭喜你完成{{ stage }}阶的全部练习</div>
        <button @click="tofillin2">完成勋章报告 领取报告</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  homePage,
  getUserInfo,
  getStageIsComplated,
  memberLog,
} from "../api/login";

export default {
  data() {
    return {
      userId: "",
      active: 3,
      icon: {
        active:
          "https://lesson.iapeap.com/images/EmotionalValue/pengyouquan.png",
        inactive:
          "https://lesson.iapeap.com/images/EmotionalValue/pengyouquan.png",
        jnh: "https://lesson.iapeap.com/images/EmotionalValue/jnh.png",
        lxdah: "https://lesson.iapeap.com/images/EmotionalValue/lxdah.png",
      },
      days: "0",
      stage: "",
      showimg: false,
      daily_pic: "your_image_url_here",
      timer: null,
      daily_pic: "",
      daily_sentence: "",
      task_title: "",
      shownoover: false,
      showover: false,
      starttime: "", //  进入页面时间
      endtime: "", //  退出页面时间
      time: "", //   页面累积时间
      unitId: "",
      company: "", //公司
      accomplish: "0",
      mdeal: "",
    };
  },
  created() {
    this.userId = window.localStorage.getItem("userId");
    if (this.userId == "undefined" || !this.userId) {
      Toast.loading({
        message: "尚未登陆",
        forbidClick: true,
      });
      setTimeout(() => {
        this.$router.push("/login");
      }, 1500);
    } else {
      this.gethomePage();
      this.getgetUserInfo();
    }
  },

  methods: {
    riqian() {
      // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
      const day = String(currentDate.getDate()).padStart(2, "0");
      const hours = String(currentDate.getHours()).padStart(2, "0");
      const minutes = String(currentDate.getMinutes()).padStart(2, "0");
      const seconds = String(currentDate.getSeconds()).padStart(2, "0");
      this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      console.log("离开页面的系统时间是", this.endttime);

      //把参数传给后端接口，starttime、endttime、time
      let data = {
        startTime: this.endttime,
        endTime: this.endttime,
        userId: window.localStorage.getItem("userId"),
        pageName: "点击日签　",
        timeStamp: 0,
      };
      memberLog(data).then((res) => {});

      this.showimg = !this.showimg;
    },
    // 查询个人信息，查询是否填写个人信息
    getgetUserInfo() {
      let data = { userId: window.localStorage.getItem("userId") };
      getUserInfo(data).then((res) => {
        console.log(res.data);
        if (res.data.Prop15 == null) {
          Toast.loading({
            message: "暂未完整信息",
            forbidClick: true,
          });
          setTimeout(() => {
            this.$router.push("/user/usermsg");
          }, 2500);
        }
      });
    },
    navigateToCapsule() {
      this.$router.push("/capsule/capsule");
    },
    navigateTofile() {
      this.$router.push("/practice/file");
    },
    gotosetouindex() {
      this.$router.push("/setout/index");
    },
    navigateTocircle() {
      this.$router.push("/circle/circle");
    },

    //获取当天阶段内容
    gethomePage() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: "正在加载...",
        forbidClick: true,
      });
      let data = { userId: window.localStorage.getItem("userId") };
      homePage(data)
        .then((res) => {
          Toast.clear();
          this.days = res.days;
          this.stage = res.stage;
          this.daily_pic = res.daily_pic;
          this.daily_sentence = res.daily_sentence;
          this.task_title = res.task_title;

          console.log(
            "今天是第",
            this.stage,
            "阶段",
            "   ",
            "第",
            this.days,
            "天"
          );
          this.getgetStageIsComplated();
          this.getgetStageIsComplated2();
        })
        .catch((error) => {});
    },

    //长按保存

    startTimer() {
      // Start the timer when mouse is pressed or touch is detected
      this.timer = setTimeout(() => {
        this.saveImage();
      }, 1500); // You can adjust the duration (in milliseconds) for the long-press here
    },
    stopTimer() {
      // Stop the timer when mouse is released or touch ends
      clearTimeout(this.timer);
    },
    saveImage() {
      // For example, you can create an anchor element and simulate a click on it:
      const a = document.createElement("a");
      a.href = this.daily_pic;
      a.download = "image.jpg"; // Set the desired file name here
      a.click();
    },
    // 查看上一个阶段是否全部完成
    getgetStageIsComplated() {
      console.log("上一阶段是：第", this.stage - 1, "阶段");
      //如果当前阶段大于1阶段调用
      if (this.stage > 1) {
        let data = {
          userId: window.localStorage.getItem("userId"),
          stage: this.stage - 1,
          // 用当前阶段减1来查看
        };

        getStageIsComplated(data).then((res) => {
          this.accomplish = res.data.status;
          this.mdeal = res.data.mdeal;
          if (this.accomplish == 0 && !this.mdeal) {
            this.shownoover = !this.shownoover;
          }
        });
      }
    },

    // 获取当前阶段是否全部完成
    getgetStageIsComplated2() {
      let data = {
        userId: window.localStorage.getItem("userId"),
        stage: this.stage,
      };
      getStageIsComplated(data).then((res) => {
        this.accomplish = res.data.status;
        this.mdeal = res.data.mdeal;
        if (this.accomplish !== 0 && !this.mdeal) {
          this.showover = !this.showover;
        }
      });
    },
    // 跳转未完成填写勋章报告
    tofillin() {
      this.$router.push({
        path: "/report/fillin",
        query: { stage: this.stage - 1, ok: 0 },
      });
    },

    // 跳转已完成填写勋章报告
    tofillin2() {
      this.$router.push({
        path: "/report/fillin",
        query: { stage: this.stage, ok: 1 },
      });
    },
  },

  mounted() {
    //如果没有缓存unitId   把地址栏的参数放进去
    if (!window.localStorage.getItem("unitId")) {
      window.localStorage.setItem("unitId", this.$route.query.unitId);
    }

    // 如果缓存unitId 不等于参数unitId 替换
    if (
      this.$route.query.unitId &&
      this.$route.query.unitId !== window.localStorage.getItem("unitId")
    ) {
      window.localStorage.setItem("unitId", this.$route.query.unitId);
    }

    if (window.localStorage.getItem("unitId") == "undefined") {
      this.company = "高情商训练营";
    }
    if (window.localStorage.getItem("unitId") == "sys1818") {
      this.company = "情绪价值训练营";
    }
    if (window.localStorage.getItem("unitId") == "1397") {
      this.company = "高情商训练营";
    }

    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "主页",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
};
</script>

<style lang="less">
.navbar {
  background-color: #ededed;
}
.tanchuang {
  width: 100%;
  height: 100%;
  background: RGBA(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .riqian {
    .guanbi {
      float: right;
      width: 25px;
      margin-top: 5px;
    }
    .riqianimg {
      margin-top: 5px;
      height: 426px;
    }
  }
}
.home {
  background-color: #f4fafe;
  min-height: calc(100vh - 72px);
  .content {
    min-height: 100%;
    .contentbox {
      padding: 38px 20px 0px 20px;
      box-sizing: border-box;
    }
    .content-title {
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      img {
        width: 74px;
        height: 57px;
      }
    }
    .content-ctx {
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #000025;
      line-height: 28px;
      span {
        height: 22px;
        font-size: 10px;
        font-weight: 400;
        color: #000025;
        line-height: 14px;
      }
      b {
        font-size: 12px;
        color: #629ee9;
      }
    }
    .content-ctx2 {
      height: 78px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .ctx2Item {
        width: 33px;
        height: 6px;
        background: #72acf4;
        border-radius: 8px;
      }

      .ctx2Item2 {
        width: 33px;
        height: 6px;
        background: #becee3;
        border-radius: 8px;
      }
    }
    .num {
      width: 16px;
      height: 17px;
      font-size: 12px;
      font-weight: 500;
      color: #333;
      line-height: 17px;
      position: relative;
      left: 300px;
      bottom: 20px;
      span {
        color: #4ca0ef;
      }
    }
    .ctx3 {
      background-image: url("https://lesson.iapeap.com/images/EmotionalValue/shouyerili.png");
      background-size: 100% 100%;
      width: 100%;
      height: 250px;
      padding: 30px 20px 20px 20px;
      box-sizing: border-box;
      .ctx3-content1 {
        text-align: center;
        font-size: 18px;
      }
      .ctx3-content2 {
        margin-top: 30px;
        text-indent: 20px;
        font-size: 16px;
        line-height: 20px;
      }
      .ctx3-content3 {
        text-align: center;
      }
    }
    .shengchengriqian {
      color: #4ca0ef;
      font-size: 14px;
      text-align: center;
      position: relative;
      bottom: 40px;
      background: #fff;
      margin: 0 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;
      background-color: #fff;
      img {
        width: 12px;
      }
    }
    .ctx4 {
      display: flex;
      justify-content: center;
      button {
        border: 0;
        width: 180px;
        height: 36px;
        background: #4ca0ef;
        color: #fff;
        border-radius: 25px;
      }
    }

    .ctx5 {
      display: flex;
      justify-content: center;
      button {
        border: 0;
        width: 180px;
        height: 36px;
        background: #53575b;
        color: #fff;
        border-radius: 25px;
      }
    }
  }
  .tabbar {
    height: 72px;
    z-index: 0;
  }
  .noover {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background-color: RGBA(250, 253, 254, 0.9);
    .nooverbox {
      width: 248px;
      height: 295px;
      background-color: #fff;
      box-shadow: 1px 4px 4px 2px rgba(0, 0, 0, 0.192);
      border-radius: 25px;
      text-align: center;
      color: #333;
      padding: 0 20px;
      box-sizing: border-box;
      .nooverbox-ctx {
        margin-top: -60px;
        height: 154px;
        font-size: 16px;
        font-weight: 400;
        color: #000025;
        line-height: 22px;
        text-align: left;

        span {
          color: #72acf4;
        }
      }
      img {
        position: relative;
        bottom: 80px;
        width: 114x;
        height: 98px;
      }
      button {
        margin-top: 20px;
        color: #fff;
        border-radius: 25px;
        background-color: #72acf4;
        height: 36px;
        border: 0;
        width: 100%;
      }
    }
  }
  .over {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background-color: rgba(250, 253, 254, 0.9);
    .overbox {
      width: 300px;
      height: 304px;
      background-color: #fff;
      box-shadow: 1px 4px 4px 2px rgba(0, 0, 0, 0.192);
      border-radius: 25px;
      text-align: center;
      color: #333;
      img {
        position: relative;
        // left: calc(50% - 75px);
        bottom: 30px;
        width: 150px;
        height: 140px;
      }
      button {
        color: #fff;
        border-radius: 25px;
        background-color: #72acf4;
        width: 224px;
        height: 36px;
        border: 0;
        margin-top: 30px;
      }
    }
  }
}
</style>
